function fetch_excel(url, params, filename) {
  const token = localStorage.getItem('token')
  let new_url = new URL(url)
  Object.keys(params).forEach(key => {
    if (params[key]) {
      new_url.searchParams.append(key, params[key])
    }
  })
  return fetch(new_url, {
    method: 'GET',
    headers: Object.assign({
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  })
    .then(response => {
      if (response.ok) {
        return response.blob()
      } else {
        return response.json()
      }
    })
    .then(blob => {
      if (blob.message) {
        throw blob.message
      }

      var url = window.URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove() //afterwards we remove the element again
    })
}

export default fetch_excel
