<template>
  <div>
    <IncomeList title="微信" :pay-channel="'WXPAY'" />
  </div>
</template>
<script>
import IncomeList from '../IncomeList'
export default {
  components: {
    IncomeList,
  },
}
</script>
