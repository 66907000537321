import { createList } from 'shared/pages'
export default [
  {
    path: '',
    name: 'doctor_list',
    component: () =>
      import('./List').then(config => createList(config.default)),
  },
  {
    path: 'create',
    name: 'doctor_new',
    component: () => import('./New.vue'),
  },
  {
    path: ':id/edit',
    name: 'doctor_edit',
    component: () => import('./Edit'),
    props: true,
  },
  {
    path: ':id',
    name: 'doctor_detail',
    component: () => import('./Detail'),
    props: true,
  },
]
