// import { createList } from 'shared/pages'
export default [
  {
    path: '',
    name: 'inventory_list',
    component: () => import('./List.vue'),
  },
  // {
  //   path: ':id',
  //   name: 'invoice_application_detail',
  //   component: () => import('./Detail'),
  //   props: true,
  // },
  // {
  //   path: ':id/edit',
  //   name: 'invoice_application_edit',
  //   component: () => import('./Edit'),
  //   props: true,
  // },
]
