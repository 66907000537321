export default [
  {
    path: '',
    name: 'product_package_list',
    component: () => import('./List'),
  },
  {
    path: 'new',
    name: 'product_package_new',
    component: () => import('./New'),
  },
  {
    path: ':id',
    name: 'product_package_detail',
    component: () => import('./Detail'),
    props: true,
  },
  {
    path: ':id/edit',
    name: 'product_package_edit',
    component: () => import('./Edit'),
    props: true,
  },
]
