<template>
  <div>
    <IncomeList title="现金" :pay-channel="'CASH'" />
  </div>
</template>
<script>
import IncomeList from '../IncomeList'
export default {
  components: {
    IncomeList,
  },
}
</script>
