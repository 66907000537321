import { createList } from 'shared/pages'
export default [
  {
    path: '',
    name: 'vip_list',
    component: () =>
      import('./List').then(config => createList(config.default)),
  },
  {
    path: ':id',
    name: 'vip_detail',
    component: () => import('./Detail'),
    props: true,
  },
]
