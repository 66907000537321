<template>
  <div v-if="$store.state.user">
    <Header
      :nav-collapse="navCollapse"
      @changeNavCollapse="changeNavCollapse"
    />
    <el-container class="home flex">
      <el-aside class="aside" width="180">
        <Menu :nav-collapse="navCollapse" />
      </el-aside>
      <el-main class="bg-gray-100 main">
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Header from './PageHeader.vue'
import Menu from './PageMenu.vue'
import api from '@/api'

export default {
  name: 'Home',
  components: {
    Header,
    Menu,
  },
  data() {
    return {
      navCollapse: false,
    }
  },
  mounted() {
    api.profile().then(res => {
      this.$store.state.user = res.data
    })
  },
  methods: {
    changeNavCollapse() {
      this.navCollapse = !this.navCollapse
    },
  },
}
</script>
<style lang="scss" scoped>
.home {
  min-height: calc(100vh - 60px);
}
.main {
  margin-top: 60px;
  height: calc(100vh - 60px);
}
.aside {
  margin-top: 60px;
  height: calc(100vh - 60px);
  overflow: auto;
}
</style>
