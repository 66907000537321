import axios from 'axios'
import http from 'shared/http'
import fetch_excel from './client'

export const getToken = () => localStorage.getItem('token')
import { Message } from 'element-ui'
function getApiPath() {
  switch (process.env.ENV) {
    case 'prod':
      return 'https://api.suwenxuan.cn/store'
    case 'staging':
      return 'https://api.suwenxuan.net/store'
    case 'local':
      // return 'https://api.suwenxuan.net/store'
      return 'http://localhost:8000/store'
    default:
      return 'http://localhost:8000/store'
    // return 'https://api.suwenxuan.net/store'
  }
}
export const apiPath = getApiPath()

export const api = axios.create({
  baseURL: apiPath,
})

api.interceptors.request.use(config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.message == 'Network Error') {
      Message({
        showClose: true,
        message: '网络错误',
        type: 'error',
      })
      throw error
    }
    if (!error.response || error.response.status > 500) {
      Message({
        showClose: true,
        message: '系统异常,请稍后再试！',
        type: 'error',
      })
    } else if (error.response.data) {
      if (error.response.status == 401) {
        localStorage.removeItem('token')
        const router = require('./router').default
        router.push({
          path: '/login',
          query: { redirect: router.history.current.fullPath },
        })
      } else {
        Message({
          showClose: true,
          message: error.response.data.message || error.response.data,
          type: 'error',
        })
      }
      throw error
    }

    throw error
  }
)

function makeApi(endPoint, extras) {
  return http.makeApi(api, endPoint, extras)
}
const vip = makeApi('/vip_level', {
  discounts(id) {
    return api.get(`/vip_level/${id}/discounts`)
  },
})
const specs = id => {
  return api.get(`/product/${id}/specs`)
}
const product = makeApi('/product', {
  availableCompos(id) {
    return api.get(`product/${id}/available_spec_combos`)
  },
  createSpec(id, form) {
    return api.post(`product/${id}/create_spec`, form)
  },
})
const staff = makeApi('/staff')
const order = makeApi('/order', {
  export_express: (params, filename) =>
    fetch_excel(apiPath + '/order/export_express', params, filename),
  export: (params, filename) =>
    fetch_excel(apiPath + '/order/export', params, filename),
  process_pharmacy_order: id => api.post(`/order/${id}/process_pharmacy_order`),
  export_medicine_fee: (params, filename) =>
    fetch_excel(apiPath + '/order/export_medicine_fee', params, filename),
})
const specCartItem = makeApi('/spec_cart_item')
const exportSpecCartItem = (params, filename) =>
  fetch_excel(apiPath + '/spec_cart_item/export', params, filename)

const doctors = makeApi('/doctors')
const addDoctor = makeApi('/doctors/add')
const editDoctor = (id, form) => {
  return api.post(`/doctor/${id}/edit`, form)
}

const doctor = makeApi('/doctor')
const doctorSort = makeApi('/doctors/sort')

const services = makeApi('/services')
const servicesSort = makeApi('/services/sort')
const categoryServices = makeApi('/category_services')

const addService = makeApi('/services/add')
const service = makeApi('/service')
const editService = (id, form) => {
  return api.post(`/service/${id}/edit`, form)
}

const changeAgendaOrder = (id, form) => {
  return api.post(`agenda/${id}/change`, form)
}

const plans = makeApi('/plans')
const planDetail = makeApi(`/plan`)

const weekPlans = makeApi('/week_plans')
const makeWholeWeekday = makeApi('/plan/bootstrap_whole_week')
const makeWholeWeekdayModule = makeApi('/week_plans')
const makeWeekdayPlan = makeApi('/plan/make_weekday_plan')
const makeWeekdayPlanEdit = (id, form) => {
  return api.post(`/plan/${id}/remake_plan`, form)
}
const addPlans = makeApi('/plains/add')

const doctor_services = makeApi('/doctor_services')
const doctor_service = makeApi('/doctor_service')
const addDoctorService = makeApi('/doctor_services/add')
const editDoctorService = (id, form) => {
  return api.post(`/doctor_service/${id}/edit`, form)
}
const addPlain = makeApi('/make_plan')
const updatePlan = (id, form) => {
  return api.post(`/make_plan/${id}`, form)
}

const enablePlan = id => {
  return api.post(`/plan/${id}/enable`)
}
const disablePlan = id => {
  return api.post(`/plan/${id}/disable`)
}
const deletePlan = id => {
  return api.post(`/plan/${id}/delete`)
}
const agenda = makeApi('/agenda')
const agendaCancle = (id, form) => {
  return api.post(`/agenda/${id}/cancel`, form)
}
const refundAgendaOrder = (id, form) => {
  return api.post(`/agenda/${id}/refund`, form)
}
const agendaApprove = id => {
  return api.post(`/agenda/${id}/approve`)
}
const agendaOrder = makeApi('/agenda/orders')

const appointSummaryDoctors = makeApi('/appoint_summary')
const makeAppointment = makeApi('/agenda/make_appointment')
const makeFiveMinuteAppointment = makeApi('/agenda/make_custom_appointment')
const stakeholders = makeApi('/stakeholders')
const exportStakeholders = (params, filename) =>
  fetch_excel(apiPath + '/stakeholders/export', params, filename)
const stakeholderMedicalRecords = (id, params) => {
  return api.get(`/stakeholder/${id}/medical_records`, { params })
}

const stakeholder = makeApi('/stakeholder')

function genExportedFileName(name, date_from, date_to) {
  if (date_from && date_to) {
    return `${name}——${date_from}——${date_to}`
  }
  return name
}

const exportAppointment = (params, filename = '预约信息') => {
  return fetch_excel(
    apiPath + '/agenda/orders/export',
    params,
    genExportedFileName(filename, params.date_from, params.date_to)
  )
}

const doctorServiceAgenda = (id, params) => {
  return api.get(`/doctor_service/${id}/agenda`, { params })
}
const doctorServiceDateAgenda = (id, date) => {
  return api.get(`/doctor_service/${id}/date/${date}/agenda`)
}
const income = makeApi('/incoming_voucher')
const exportIncomeItem = (params, filename = '收入报表') => {
  return fetch_excel(
    apiPath + '/incoming_voucher/export',
    params,
    genExportedFileName(filename, params.paid_at__gt, params.paid_at__lt)
  )
}

const productCategory = makeApi('/product_category')
const productSpec = makeApi('/product_spec')
const discount = makeApi('/discount')

const invoiceContents = makeApi('/invoice_contents')
const invoiceApplications = makeApi('/invoice_applications')
const invoiceApplication = makeApi('/invoice_application')

const stockInList = makeApi('/product_specs')
const exportProductSpecs = (params, filename = '实时库存报表') => {
  return fetch_excel(apiPath + '/product_specs/export', params, filename)
}
const productPecsDaily = makeApi('/product_specs_daily/dates')
const exportProductPecsDaily = (params, filename = '报表') => {
  return fetch_excel(apiPath + '/product_specs_daily/export', params, filename)
}

const stockIn = makeApi('/inventories/stock_in')
const exportStockIn = (params, filename = '入库报表') => {
  return fetch_excel(apiPath + '/inventories/stock_in/export', params, filename)
}
const stockInAdd = makeApi('/inventories/stock_in/add')
const stockOutAdd = makeApi('/inventories/stock_out/add')
const stockOut = makeApi('/inventories/stock_out')
const exportStockOut = (params, filename = '出库报表') => {
  return fetch_excel(
    apiPath + '/inventories/stock_out/export',
    params,
    filename
  )
}

const stockAdjust = makeApi('/inventories/stock_adjust')
const exportStockAdjust = (params, filename = '库存调整报表') => {
  return fetch_excel(
    apiPath + '/inventories/stock_adjust/export',
    params,
    filename
  )
}
const stockAdjustAdd = makeApi('/inventories/stock_adjust/add')

const approveInvoice = id => {
  return api.post(`/invoice_application/${id}/approve`)
}
const resetPassword = (id, form) => {
  return api.post(`/doctor/${id}/reset_password`, form)
}

const cancelInvoice = id => {
  return api.post(`/invoice_application/${id}/cancel`)
}
const editInvoice = (id, form) => {
  return api.post(`/invoice_application/${id}/edit`, form)
}
const modifyRemark = (id, remark) => {
  return api.post(`/agenda/${id}/edit`, remark)
}
const createInvoice = makeApi('/invoice_applications/add')

const product_package = makeApi('/product_package')

const consult = makeApi('/consult_sessions')
const consultDetail = id => {
  return api.get(`/consult_session/${id}`)
}
const zqActivities = makeApi('/zanquan_activities')
function ping() {
  return api.get('/ping')
}

function login(form) {
  return api.post('/login', form)
}
function profile() {
  return api.get('/profile')
}
function acquireUploadToken() {
  return api.post('upload_token')
}
function sendCode(id) {
  return api.post(`/agenda/${id}/notify`)
}

function doctorServiceDelete(id) {
  return api.post(`/doctor_service/${id}/delete`)
}

function fetchGoodsProducts() {
  return api.get(`/products?goods=True&per_page=9999`)
}

const vipLevelCategory = id => {
  return api.get(`/vip_level/${id}/category_services_discount`)
}

const editServicesDiscount = form => {
  return api.post(`/edit_services_discount`, form)
}
const cancelServicesDiscount = form => {
  return api.post(`/cancel_services_discount`, form)
}
const canAgendaServices = (id, date, step) => {
  return api.get(`/doctor/${id}/available_services/${date}/start_at/${step}`)
}
const previewAppointment = form => {
  return api.post(`/preview_appointment`, form)
}
const makeAppointmentNew = form => {
  return api.post(`/agenda/make_appointment_new`, form)
}

export default {
  fetchGoodsProducts,
  specs,
  acquireUploadToken,
  vip,
  productSpec,
  productCategory,
  product,
  discount,
  profile,
  ping,
  login,
  staff,
  order,
  specCartItem,
  exportSpecCartItem,
  income,
  exportIncomeItem,
  doctors,
  doctor,
  service,
  editDoctor,
  plans,
  addPlans,
  categoryServices,
  services,
  addService,
  addDoctor,
  doctor_services,
  doctor_service,
  addDoctorService,
  editService,
  editDoctorService,
  addPlain,
  agenda,
  agendaCancle,
  enablePlan,
  disablePlan,
  deletePlan,
  agendaOrder,
  agendaApprove,
  appointSummaryDoctors,
  makeAppointment,
  makeFiveMinuteAppointment,
  stakeholders,
  stakeholder,
  stakeholderMedicalRecords,
  doctorServiceAgenda,
  doctorServiceDateAgenda,
  doctorSort,
  servicesSort,
  invoiceContents,
  invoiceApplications,
  invoiceApplication,
  approveInvoice,
  resetPassword,
  cancelInvoice,
  editInvoice,
  createInvoice,
  weekPlans,
  makeWholeWeekday,
  makeWholeWeekdayModule,
  makeWeekdayPlan,
  exportAppointment,
  sendCode,
  stockInList,
  exportProductSpecs,
  productPecsDaily,
  exportProductPecsDaily,
  stockIn,
  exportStockIn,
  stockInAdd,
  stockOutAdd,
  stockOut,
  exportStockOut,
  stockAdjust,
  exportStockAdjust,
  stockAdjustAdd,
  modifyRemark,
  consult,
  consultDetail,
  zqActivities,
  doctorServiceDelete,
  vipLevelCategory,
  editServicesDiscount,
  exportStakeholders,
  updatePlan,
  planDetail,
  makeWeekdayPlanEdit,
  cancelServicesDiscount,
  canAgendaServices,
  previewAppointment,
  makeAppointmentNew,
  product_package,
  changeAgendaOrder,
  api,
  refundAgendaOrder,
  request: api,
  genExportedFileName,
}
