import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Root from './pages/Root'
import DashBoard from './pages/Dashboard'
import RouterView from './RouterView'
import VipRoutes from './pages/vip/routes'
import ProductRoutes from './pages/product/routes'
import ProductPackageRoutes from './pages/product_package/routes'
import StaffRoutes from './pages/staff/routes'
import OrderRoutes from './pages/order/routes'
import SaleRoutes from './pages/sale/routes'
import AliPay from './pages/income/alipay/List'
import Cash from './pages/income/cash/List'
import OtherPay from './pages/income/other/List'
import WxChat from './pages/income/wxchat/List'
import DoctorRoutes from './pages/doctor/routes'
import ServiceRoutes from './pages/appointment/services/routes'
import DoctorServiceRoutes from './pages/appointment/doctor-services/routes'
import PlansRoutes from './pages/appointment/plans/routes'
import WeekPlansRoutes from './pages/appointment/week-plans/routes'
import WeekPlansModuleRoutes from './pages/appointment/plans-module/routes'

import AppointmentRoutes from './pages/appointment/appointment/routes'
import AgendaRoutes from './pages/appointment/agenda/routes'
import InvoiceRoutes from './pages/invoice/routes'
import StakeholderRoutes from './pages/stakeholder/routes'
import InventoryAll from './pages/inventory/all/routes'
import Daily from './pages/inventory/daily/routes'
import StockIn from './pages/inventory/stock-in/routes'
import StockOut from './pages/inventory/stock-out/routes'
import StockAdjust from './pages/inventory/adjust/routes'
import Consult from './pages/consult/routes'
import GoodsOrderRoutes from './pages/goods_order/routes'
import RefundRoutes from './pages/refund/routes'
import MessageRoutes from './pages/message/routes'
import UserRoutes from './pages/user/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Root,
      children: [
        {
          path: '/',
          component: DashBoard,
        },
        {
          path: '/vip',
          component: RouterView,
          children: VipRoutes,
        },
        {
          path: '/product',
          component: RouterView,
          children: ProductRoutes,
        },
        {
          path: '/product_package',
          component: RouterView,
          children: ProductPackageRoutes,
        },
        {
          path: '/staff',
          component: RouterView,
          children: StaffRoutes,
        },
        {
          path: '/order',
          component: RouterView,
          children: OrderRoutes,
        },
        {
          path: '/sale_volume',
          component: RouterView,
          children: SaleRoutes,
        },
        {
          path: '/doctors',
          component: RouterView,
          children: DoctorRoutes,
        },
        {
          path: '/services',
          component: RouterView,
          children: ServiceRoutes,
        },
        {
          path: '/doctor-service',
          component: RouterView,
          children: DoctorServiceRoutes,
        },
        {
          path: '/plans',
          component: RouterView,
          children: PlansRoutes,
        },
        {
          path: '/week-plans',
          component: RouterView,
          children: WeekPlansRoutes,
        },
        {
          path: '/module-plans',
          component: RouterView,
          children: WeekPlansModuleRoutes,
        },
        {
          path: '/appointment',
          component: RouterView,
          children: AppointmentRoutes,
        },
        {
          path: '/agenda',
          component: RouterView,
          children: AgendaRoutes,
        },
        {
          path: '/invoice',
          component: RouterView,
          children: InvoiceRoutes,
        },
        {
          path: '/alipay',
          component: AliPay,
          name: 'alipay_list',
        },
        {
          path: '/wxchat',
          component: WxChat,
          name: 'wxchat_list',
        },
        {
          path: '/cash',
          component: Cash,
          name: 'cash_list',
        },
        {
          path: '/other',
          component: OtherPay,
          name: 'other_list',
        },
        {
          path: '/stakeholder',
          component: RouterView,
          children: StakeholderRoutes,
        },
        {
          path: '/inventory',
          component: RouterView,
          children: InventoryAll,
        },
        {
          path: '/stockin',
          component: RouterView,
          children: StockIn,
        },
        {
          path: '/stockout',
          component: RouterView,
          children: StockOut,
        },
        {
          path: '/adjust',
          component: RouterView,
          children: StockAdjust,
        },
        {
          path: '/daily',
          component: RouterView,
          children: Daily,
        },
        {
          path: '/consult',
          component: RouterView,
          children: Consult,
        },
        {
          path: '/goods_order',
          component: RouterView,
          children: GoodsOrderRoutes,
        },
        {
          path: '/refund',
          component: RouterView,
          children: RefundRoutes,
        },
        {
          path: '/message',
          component: RouterView,
          children: MessageRoutes,
        },
        {
          path: '/user',
          component: RouterView,
          children: UserRoutes,
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./pages/Login.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('./pages/NotFound.vue'),
    },
    {
      path: '*',
      redirect: {
        name: '404',
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.path === '*') ||
    ['/login'].includes(to.path) ||
    store.state.isLogin
  ) {
    next()
  } else {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    })
  }
})

export default router
