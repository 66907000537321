// import { createList } from 'shared/pages'
export default [
  {
    path: '',
    name: 'stock_in',
    component: () => import('./List.vue'),
  },
  {
    path: 'add',
    name: 'add_stock_in',
    component: () => import('./New.vue'),
  },
  // {
  //   path: ':id/edit',
  //   name: 'invoice_application_edit',
  //   component: () => import('./Edit'),
  //   props: true,
  // },
]
