<template>
  <el-menu
    v-if="user"
    :collapse="navCollapse"
    router
    class="menu"
    :default-active="activePath"
  >
    <el-submenu v-if="navCollapse" index="/">
      <template slot="title">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </template>
      <el-menu-item index="/" :class="[{ 'active-menu': $route.path === '/' }]">
        <template slot="title">
          <span slot="title">首页</span>
        </template>
      </el-menu-item>
    </el-submenu>
    <el-menu-item
      v-else
      index="/"
      :class="[{ 'active-menu': $route.path === '/' }]"
    >
      <template slot="title">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </template>
    </el-menu-item>
    <el-submenu
      v-for="menu in visibleMenus"
      :key="menu.title"
      :index="menu.index"
    >
      <template slot="title">
        <i :class="'el-icon-' + menu.icon"></i>
        <span slot="title">{{ menu.title }}</span>
      </template>
      <el-menu-item
        v-for="item in menu.menuItems"
        :key="item.path"
        :index="item.path"
        :class="[
          {
            'active-menu': activePath == item.path,
          },
        ]"
      >
        {{ item.name }}
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>
import { mapState } from 'vuex'
const menus = [
  {
    title: '就诊人',
    icon: 's-custom',
    index: 'member',
    menuItems: [
      { path: '/stakeholder', name: '就诊人' },
      { path: '/invoice', name: '开票申请' },
    ],
  },
  {
    title: '会员管理',
    icon: 'user',
    index: 'user',
    menuItems: [{ path: '/user', name: '会员列表' }],
  },
  {
    title: '退款',
    icon: 'coin',
    index: 'refund',
    menuItems: [{ path: '/refund', name: '退款管理' }],
  },
  {
    title: '门店零售',
    icon: 'goods',
    index: 'goods',
    menuItems: [
      { path: '/product', name: '商品管理' },
      { path: '/product_package', name: '商品套餐' },
      { path: '/order', name: '订单列表' },
      { path: '/order/pharmacy', name: '配药订单' },
      { path: '/sale_volume', name: '商品销量' },
    ],
  },
  {
    title: '门店库存',
    icon: 'receiving',
    index: 'el-icon-truck',
    menuItems: [
      { path: '/inventory', name: '实时库存' },
      { path: '/daily', name: '每日库存' },
      { path: '/stockin', name: '入库单' },
      { path: '/stockout', name: '出库单' },
      { path: '/adjust', name: '库存调整单' },
    ],
  },
  {
    title: '收入',
    icon: 's-finance',
    index: 'income',
    menuItems: [
      { path: '/wxchat', name: '微信' },
      { path: '/alipay', name: '支付宝' },
      { path: '/cash', name: '现金' },
      { path: '/other', name: '其他' },
    ],
  },
  {
    title: '服务项目',
    icon: 'printer',
    index: 'service',
    menuItems: [
      // { path: '/services', name: '诊疗项目' },
      { path: '/doctor-service', name: '医生诊疗项目' },
    ],
  },
  {
    title: '医生排班',
    icon: 'document-add',
    index: 'document-add',
    menuItems: [
      { path: '/plans', name: '医生每日排班' },
      { path: '/week-plans', name: '医生每周排班' },
      { path: '/module-plans', name: '周排班模版' },
    ],
  },
  {
    title: '预约挂号',
    icon: 'date',
    index: 'appointment',
    menuItems: [
      { path: '/agenda', name: '预约管理' },
      { path: '/appointment', name: '预约记录' },
    ],
  },
  {
    title: '网上问诊',
    icon: 'first-aid-kit',
    index: 'consult_list',
    menuItems: [{ path: '/consult', name: '在线问诊' }],
  },
  {
    title: '人员管理',
    icon: 'service',
    index: 'staff',
    menuItems: [
      { path: '/doctors', name: '医生列表' },
      { path: '/staff', name: '员工列表' },
    ],
  },
  {
    title: '网上商城',
    icon: 's-goods',
    index: 'mall',
    menuItems: [{ path: '/goods_order', name: '订单管理' }],
  },
  {
    title: '个人中心',
    icon: 'setting',
    index: 'setting',
    menuItems: [{ path: '/message', name: '我的消息' }],
  },
]
export default {
  name: 'PageMenu',
  props: {
    navCollapse: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(['user']),
    visibleMenus() {
      if (!this.user) {
        return []
      }
      // 这个账号只看电商
      if (this.user.mobile == '18981180287')
        return menus.filter(
          menu => menu.index == 'mall' || menu.index == 'goods'
        )

      if (this.user.role_display == 'STAFF') {
        const visibleToSchedule = [
          '15821911041',
          '13262290813',
          '18521530284',
          '15900920597',
          '18930511489',
          '13764040429',
        ]

        if (visibleToSchedule.includes(this.user.mobile)) {
          return menus
        }
        return menus.filter(menu => menu.index !== 'document-add')
      }
      if (this.user.role_display == 'ASSISTANT') {
        // return menus
        return menus.filter(menu =>
          [
            'appointment',
            'service',
            'mall',
            'member',
            'goods',
            'consult_list',
            'user',
            'refund',
          ].includes(menu.index)
        )
      }
      throw '用户的角色只能是STAFF或者ASSISTANT'
    },
    activePath() {
      if (this.$route.path === '/') {
        return '/'
      }
      const activeMenu = this.getActiveMenu(this.visibleMenus)
      return activeMenu.path
    },
  },
  watch: {
    visibleMenus() {
      if (this.$route.path === '/') {
        return
      }
      const activeMenu = this.getActiveMenu(this.visibleMenus)
      if (!activeMenu) {
        this.$router.replace('/')
      }
    },
  },
  methods: {
    getActiveMenu(menus) {
      return menus
        .flatMap(item => item.menuItems)
        .find(menu => this.$route.path.startsWith(menu.path))
    },
  },
}
</script>
<style lang="scss" scoped>
.menu-fixed-position {
  top: 60px;
}
.menu:not(.el-menu--collapse) {
  width: 180px;
}

.menu {
  height: 100%;
  background: #fff;
}
.active-menu {
  background-color: #ebf5ff;
  @apply border-primary-dark border-r-4;
}
</style>
