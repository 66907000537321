import Vue from 'vue'
import { inject } from 'vue'
import Vuex from 'vuex'
import api from './api'
import { computed } from 'vue'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: null,
    unread_message_count: null,
    isLogin: !!localStorage.getItem('token'),
  },
  actions: {
    async getUnreadMessageCount({ state, commit }) {
      if (!state.isLogin) {
        return
      }
      return api.api.get('messages/count_unread').then(response => {
        commit('setUnreadMessageCount', response.data.count)
      })
    },
    async login({ commit }, form) {
      return api.login(form).then(response => {
        localStorage.setItem('token', response.data.token)
        commit('login')
      })
    },
  },
  mutations: {
    setUnreadMessageCount(state, count) {
      state.unread_message_count = count
    },
    login(state) {
      state.isLogin = true
    },
  },
})
type User = {
  roles: string[]
}

export const useStore = () => inject<typeof store>('store')!
export const useUser = () => {
  const store = useStore()
  return computed(() => store.state.user! as User)
}

export default store
