import Vue, { provide } from 'vue'
import ElementUI, { MessageBox } from 'element-ui'
import './base.css'
import './theme/index.css'
import './tw.css'
Vue.use(ElementUI, { size: 'small' })
// import 'element-ui/lib/theme-chalk/index.css';

import formMixin from 'shared/mixins/formMixin'
import queryMixin from 'shared/mixins/queryMixin'
import beforEachEntry from 'shared/mixins/beforEachEntry'

import Pagination from 'shared/components/Pagination'
import Card from './components/Card.vue'
import Header from './components/Header.vue'
import SearchButtons from './components/SearchButtons.vue'
import PageList from 'shared/pages/List'
import PageModel from 'shared/pages/Model'
import PageDetail from 'shared/pages/Detail'
import DetailItem from 'shared/components/DetailItem'
import Spacer from './components/Spacer.vue'
import App from './App.vue'
import * as Sentry from '@sentry/vue'
import * as consts from './consts'
import FormItem from 'shared/pages/FormItem'
import ClipLoader from 'shared/components/ClipLoader'
import RouteWrapper from 'shared/components/RouteWrapper'
import { api, apiPath } from './api'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
dayjs.extend(relativeTime)

Vue.config.productionTip = false
Vue.component('Loading', {
  render() {
    return (
      <div class="flex mt-8 justify-center">
        <ClipLoader color="#5da2ff" />
      </div>
    )
  },
})

import store from './store'
import router from './router'
import BreadCrumb from 'shared/components/BreadCrumb'
Vue.component('Route', RouteWrapper)

Vue.mixin(formMixin)
Vue.mixin(queryMixin)
Vue.mixin(beforEachEntry)

Vue.mixin({
  data() {
    return {
      api,
      apiPath,
      consts,
    }
  },
})

Vue.prototype.$confirm = (...args) => {
  return new Promise(function (resolve) {
    MessageBox.confirm(...args)
      .then(resolve)
      .catch(console.log)
  })
}

Vue.component('Pagination', Pagination)
Vue.component('Card', Card)
Vue.component('Header', Header)
Vue.component('DetailItem', DetailItem)
Vue.component('PageList', PageList)
Vue.component('PageModel', PageModel)
Vue.component('PageDetail', PageDetail)
Vue.component('FormItem', FormItem)
Vue.component('BreadCrumb', BreadCrumb)
Vue.component('SearchButtons', SearchButtons)
Vue.component('Spacer', Spacer)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    ignoreErrors: [
      /4\d{2}$/,
      'Network Error',
      /5\d{2}$/,
      /Navigation/,
      'ChunkLoadError', // 由于 webpack 的 code split，可能会出现加载动态的bundle网络错误，已经有外层的errorboudnary处理了

      /Loading CSS chunk \d+ failed/, // css chunk 加载失败，属于网络问题，不需要处理
    ],

    dsn: 'https://c819f09016f24bac9e580fb6e261e6d2@o1058326.ingest.sentry.io/6051265',

    integrations: [
      Sentry.browserTracingIntegration({
        tracingOrigins: [
          'localhost',
          'zhensuo.suwenxuan.cn',
          'zhensuo.suwenxuan.net',
          /^\//,
        ],
      }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release:
      (process.env.ENV == 'staging' ? 'staging@' : 'production@') +
      require('../package.json')['version'],
  })
}

new Vue({
  setup() {
    provide('store', store)
  },

  store,
  router,
  el: '#app',
  render: h => h(App),
})
