<template>
  <div>
    <el-header>首页</el-header>
    <el-card>
      <div class="flex justify-between p-4">
        <div class="flex flex-col items-center">
          <span class="my-4">今日诊单</span>
          <span class="text-xl">暂无</span>
        </div>
        <div class="flex flex-col items-center">
          <span class="my-4">总营业额</span>
          <span class="text-xl">暂无</span>
        </div>
        <div class="flex flex-col items-center">
          <span class="my-4">充值金额</span>
          <span class="text-xl">暂无</span>
        </div>
        <div class="flex flex-col items-center">
          <span class="my-4">冻结金额</span>
          <span class="text-xl">暂无</span>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
