var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-header", [_vm._v("首页")]),
      _c("el-card", [
        _c("div", { staticClass: "flex justify-between p-4" }, [
          _c("div", { staticClass: "flex flex-col items-center" }, [
            _c("span", { staticClass: "my-4" }, [_vm._v("今日诊单")]),
            _c("span", { staticClass: "text-xl" }, [_vm._v("暂无")]),
          ]),
          _c("div", { staticClass: "flex flex-col items-center" }, [
            _c("span", { staticClass: "my-4" }, [_vm._v("总营业额")]),
            _c("span", { staticClass: "text-xl" }, [_vm._v("暂无")]),
          ]),
          _c("div", { staticClass: "flex flex-col items-center" }, [
            _c("span", { staticClass: "my-4" }, [_vm._v("充值金额")]),
            _c("span", { staticClass: "text-xl" }, [_vm._v("暂无")]),
          ]),
          _c("div", { staticClass: "flex flex-col items-center" }, [
            _c("span", { staticClass: "my-4" }, [_vm._v("冻结金额")]),
            _c("span", { staticClass: "text-xl" }, [_vm._v("暂无")]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }