// import { createList } from 'shared/pages'
export default [
  {
    path: '',
    name: 'consult_list',
    component: () => import('./List'),
  },
  {
    path: ':id',
    name: 'consult_detail_list',
    component: () => import('./Detail/List'),
    props: true,
  },
]
