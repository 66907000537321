export const DoctorAgendaStatusEnum = {
  // PENDING: '等待审核',
  NOT_PAID: '未支付',
  PAID: '已支付',
  CANCEL: '已取消',
}
export const CANCEL = 'CANCEL'

export const DiagnoseStatusEnum = {
  CANCEL: '取消就诊',
  GIVE_UP: '放弃就诊',
  PENDING: '尚未到店',
  ARRIVAL: '等候诊疗',
  TREATMENT_STARTED: '正在治疗',
  TREATMENT_FINISHED: '治疗完毕',
  FINISHED: '完成就诊',
}

export const DeliveryEnum = {
  SELF: '自提',
  EXPRESS: '配送',
}
export const InvoiceTypeEnum = {
  INDIVIDUAL: '个人',
  COMPANY: '公司',
}
export const InvoiceApplicationStatusEnum = {
  PENDING: '等待开票',
  APPROVED: '开票成功',
  CANCELED: '开票取消',
}

export const PayStatusEnum = {
  NOT_PAID: '未支付',
  PAID: '已支付',
}
export const NOT_PAID = 'NOT_PAID'
export const PAID = 'PAID'

export const InvoiceContentEnum = {
  DIAGNOSE: '诊费',
  CONSULT: '咨询费',
  SERVICE: '服务费',
  CURE: '治疗费',
}

export const PayChannelEnum = {
  BALANCE: '余额支付',
  POINT: '积分',
  CASH: '现金',
  WXPAY: '微信支付',
  ALIPAY: '支付宝',
  OTHER: '其他',
}
export const ChannelEnum = {
  STORE: '诊所后台',
  MINIAPP: '小程序',
  STORE_OLD: '老后台预约',
}

export const WeekNum = {
  MONDAY: '周一',
  TUESDAY: '周二',
  WEDNESDAY: '周三',
  THURSDAY: '周四',
  FRIDAY: '周五',
  SATURDAY: '周六',
  SUNDAY: '周日',
}
export const WeekDays = {
  0: '周一',
  1: '周二',
  2: '周三',
  3: '周四',
  4: '周五',
  5: '周六',
  6: '周日',
}

export const ConsultStatusEnum = {
  NOT_STARTED: '未开始',
  STARTED: '已开始',
  FINISHED: '已结束',
}

export const ChatStatusEnum = {
  NOT_STARTED: '未提问',
  QUESTION: '已提问',
  ANSWER: '已回复',
  FINISHED: '已结束',
}

export const END_TIME = '21:00'

export const OrderStatus = {
  PENDING: '待付款',
  PAID: '待发货',
  SHIPPED: '已发货',
  RECEIVED: '已收货',
  CANCELLED: '已取消',
}

export const RefundStatus = {
  PENDING: '待审批',
  APPROVED: '审核通过',
  REFUNDED: '已退款',
  REJECTED: '店长拒绝退款',
  CANCELLED: '已取消',
  TERMINATE: '财务拒绝退款',
}

export const RefundBizType = {
  APPOINTMENT: '预约订单',
  GOODS: '商城订单',
  POS_ORDER: 'POS 订单',
  CUSTOM: '其他(财务退)',
}
