import api from '@/api'
export default {
  title: '商品管理',
  api: api.product.list,
  filters: [
    {
      label: '商品名称',
      field: 'name',
      placeholder: '',
    },
    {
      label: '商品分类',
      field: 'category_id',
      type: 'select',
      options: async () => {
        var { data } = await api.productCategory.all()
        data.push({ name: '全部', id: '' })
        return data
      },
    },
  ],
  actions: [],
  table: {
    fields: [
      {
        key: 'name',
        label: '商品名称',
        sortable: true,
      },
      {
        key: 'number',
        label: '商品编号',
      },
      {
        key: 'category.name',
        label: '商品分类',
      },
    ],
    operations: [
      {
        title: '查看',
        type: 'DETAIL',
        route: 'product_detail',
      },
      {
        title: '商品规格',
        type: 'DETAIL',
        route: 'product_specs',
        props: {
          plain: true,
        },
      },
    ],
  },
}
