// import { createList } from 'shared/pages'
export default [
  {
    path: '',
    name: 'plan_list',
    component: () => import('./List.vue'),
  },
  {
    path: 'create',
    name: 'plan_new',
    component: () => import('./New.vue'),
  },
  {
    path: ':id/edit',
    name: 'plan_edit',
    component: () => import('./Edit.vue'),
    props: true,
  },
]
