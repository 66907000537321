export default [
  {
    path: '',
    name: 'stakeholder_list',
    component: () => import('./List'),
  },
  {
    path: ':id/records',
    name: 'shakeholder_records',
    component: () => import('./records/List'),
    props: true,
  },
]
