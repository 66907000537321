export default [
  {
    path: '',
    name: 'doctor_service_list',
    component: () => import('./List'),
  },
  {
    path: 'create',
    name: 'doctor_service_new',
    component: () => import('./New.vue'),
  },
  {
    path: ':id/service',
    name: 'doctor_service_edit',
    component: () => import('./Edit.vue'),
    props: true,
  },
  {
    path: ':id/detail',
    name: 'doctor_service_detail',
    component: () => import('./Detail.vue'),
    props: true,
  },
]
