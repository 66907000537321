var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.state.user
    ? _c(
        "div",
        [
          _c("Header", {
            attrs: { "nav-collapse": _vm.navCollapse },
            on: { changeNavCollapse: _vm.changeNavCollapse },
          }),
          _c(
            "el-container",
            { staticClass: "home flex" },
            [
              _c(
                "el-aside",
                { staticClass: "aside", attrs: { width: "180" } },
                [_c("Menu", { attrs: { "nav-collapse": _vm.navCollapse } })],
                1
              ),
              _c(
                "el-main",
                { staticClass: "bg-gray-100 main" },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }