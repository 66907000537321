import api from '@/api'

export default {
  title: '员工列表',
  api: api.staff.list,
  filters: [
    {
      label: '姓名',
      field: 'name',
    },
    {
      label: '手机号',
      field: 'mobile',
    },
  ],

  actions: [
    {
      title: '添加员工',
      route: 'staff_new',
    },
  ],
  table: {
    fields: [
      {
        key: 'name',
        label: '员工姓名',
        // sortable: true,
      },
      {
        key: 'mobile',
        label: '手机号码',
      },
      {
        key: 'is_admin',
        label: '是否为管理员',
      },
      {
        key: 'active',
        label: '是否在职',
        type: 'boolean',
        trueText: '在职',
        falseText: '离职',
      },
    ],
    operations: [
      {
        title: '查看',
        type: 'DETAIL',
        route: 'staff_detail',
      },
      {
        title: '编辑',
        type: 'EDIT',
        route: 'staff_edit',
      },
      {
        title: '删除',
        api: api.staff.delete,
        type: 'DELETE',
      },
    ],
  },
}
