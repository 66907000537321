import page from 'shared/pages'
import List from './List'
export default [
  {
    path: '',
    name: 'product_list',
    component: page.createList(List),
  },
  {
    path: ':id',
    name: 'product_detail',
    component: () => import('./Detail'),
    props: true,
  },
  {
    path: ':id/specs',
    name: 'product_specs',
    component: () => import('./spec/List'),
    props: true,
  },
  {
    path: ':id/specs/new',
    name: 'spec_new',
    component: () => import('./spec/New'),
    props: true,
  },
  {
    path: ':id/specs/:specId/edit',
    name: 'spec_edit',
    component: () => import('./spec/Edit'),
    props: true,
  },
  {
    path: ':id/specs/:specId',
    name: 'spec_detail',
    component: () => import('./spec/Detail'),
    props: true,
  },
]
