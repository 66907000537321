// import { createList } from 'shared/pages'
export default [
  {
    path: '',
    name: 'stock_out',
    component: () => import('./List.vue'),
  },
  {
    path: 'add',
    name: 'add_stock_out',
    component: () => import('./New.vue'),
  },
]
