<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica,
    Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, sans-serif;
}

button:focus {
  outline: 0;
}
.el-table {
  margin-bottom: 32px;
  margin-top: 32px;
}
.el-table th {
  background: #f5f7fa;
}
.el-loading-spinner {
  left: 50%;
}
</style>
